@import "src/Styles/fonts";
@import "src/Styles/functions";
@import "src/Styles/variables";

.IntroContainer {
  height: 100vh;
  @extend %fontMontserrat;
}

.NavContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.logoContainer {
  position: absolute;
  top: 0;
  left: 30;
  padding: rem(10);
  img {
    height: 60px;
    width: 60px;
    margin: 0;
  }
}
.name {
  font-size: rem(35);
  margin-top: rem(100);
  margin-bottom: rem(20);
  color: #114856;
}

.animationDiv {
  width: 100%;
}

.navMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top:rem(150);
}
a {
  text-decoration: none;
}