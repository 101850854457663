.NavBubbleContainer {
  height: 150px;
  width: 150px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all .2s ease-in-out; 
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.NavBubbleContainer:hover {
  transform: scale(1.1); 
}