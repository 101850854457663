@import "src/Styles/variables";
@import "src/Styles/functions";
@import "src/Styles/fonts";

.ExperienceContainer {
  height: auto;
}

.ExperienceTitle {
  background-color: $DarkBlue;
  padding: 10px 0px;
  font-size: rem(20);
}

.jobTitle {
  // font-size: rem(15);
  color: #37515F;
  margin: rem(40);
  @extend %fontMontserrat;
}

.ExperienceGrid {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  margin: 0 auto;
  row-gap: rem(40);
  column-gap: rem(36);
  max-width: rem(1000);
  padding: rem(40) rem(100);
}

.ExperienceButton {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  // height: min-content;
}


// MODAL STYLES
.Modal {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: $BrandWhite;
  z-index: 100;
  overflow-y: scroll;
  overflow-x: hidden;

}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $BrandWhite;
  z-index: 100;
}

.CloseBtn {
  font-size: rem(30);
  position: fixed;
  right: 40px;
  top: 20px;
  cursor: pointer;
  background-color: white;
  color: $DarkBlue;
  border: 0;
  padding: 30px;
  border-radius: 100%;
  z-index: 2;
}
.CloseBtn:hover {
  // background-color: $Teal;
}
