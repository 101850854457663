@import "src/Styles/variables";
@import "src/Styles/functions";
@import "src/Styles/fonts";

.ModalInner {
    display: flex;
    flex-direction: column;
    width:100%;
    // height: 100%;
    padding: 0 0 rem(10);
    @extend %fontMontserrat;
}

.bubbleOne {
    height: rem(700);
    width: rem(700);
    background-color: rgba(38, 84, 124, .2);
    // border: 1px solid  rgba(38, 84, 124, .15);
    position: absolute;
    border-radius: 100%;
    z-index: 0;
    top: -120px;
    left: -120px;
}

.bubbleTwo {
    height: rem(400);
    width: rem(400);
    background-color: rgba(38, 84, 124, .1);
    position: absolute;
    border-radius: 100%;
    z-index: 0;
    top: 600px;
    right: -120px;
    overflow: hidden;
}

.bubbleThree {
    height: rem(70);
    width: rem(70);
    position: absolute;
    border-radius: 100%;
    border: 3px solid  rgba(38, 84, 124, .15);
    z-index: 0;
    top: 1350px;
    left: 60px;
}

.bubbleFour {
    height: rem(40);
    width: rem(40);
    position: absolute;
    border-radius: 100%;
    border: 3px solid  rgba(38, 84, 124, .15);
    z-index: 0;
    top: 780px;
    left: 15%;
}

.bubbleFive {
    height: rem(40);
    width: rem(40);
    position: absolute;
    border-radius: 100%;
    border: 3px solid  rgba(38, 84, 124, .15);
    z-index: 0;
    top: -15px;
    right: 30%;
}

h1.title {
    padding: rem(30) 0 rem(5);
    text-align: center;
}
.type {
    text-align: center;
    font-weight: 300;
    margin: 0;
}
.mainDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: rem(70) auto;
    align-content: center;
    max-width: rem(1000);
    column-gap: 20px;
    z-index: 1;
}
.mainImage {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    justify-self: center;
    background-color: rgba(255,255,255, .5);
    z-index: 1;
    padding: 10px;
    border-radius: 5px;

}
.photo {
    height: auto;
    width: rem(400);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.role {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    font-size: rem(30);
    align-self: center;
    // align-self: flex-end;
    margin: 0;
    padding: 0;
}
.roleTitle, .toolTitle {
    font-weight: bold;
    font-size: rem(20);
}
.roleList, .toolList {
    font-size: rem(20);
    text-indent: rem(20);
    padding: 0;
    margin: 0;
}
.tools {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    font-size: rem(25);
    margin: 0;
    padding: 0;
}
.description {
    margin: rem(0) auto;
    font-size: rem(20);
    line-height: rem(35);
    max-width: rem(1000);
    background-color: rgba(255,255,255, .8);
    z-index: 1;
    border-radius: 5px;
    padding: rem(8) rem(25);
}
.link {
    margin: 0 auto;
    font-size: rem(22);
    color: $Highlight;
    a {
        color: $Highlight; 
    }
}
.link:hover {
    cursor: pointer;
    font-weight: bold;
}
.galleryContainer {
    margin: 0 auto;
    height: 100%;
    width: 100%;
}

.gallery {
    height: 100%;
    width: 80%;
    margin: rem(40) auto;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh -  300px);
}
