@import "src/Styles/functions";

.RowContainer {
  grid-column: span 2;
  height: rem(300);
  overflow: hidden;
  border-radius: 25px;
}

.ColumnContainer:hover .HoverOverlay, .HalfContainer:hover .HoverOverlay, .RowContainer:hover .HoverOverlay {
  background-color:rgba(83,122,143, .9);
  color:white;
  font-weight: bold;
  z-index: 1;
}

.ColumnContainer {
  height: rem(650);
  grid-row: span 2;
  overflow: hidden;
  border-radius: 25px;
  z-index: 0;
}

.ColumnInfo, .RowInfo, .HalfInfo {
  position: relative;
  z-index: 0;
  color: white;
  font-size: rem(20);
  font-weight: bold;
  background-color: #37515f;
  padding: rem(10) 0;
}

.HoverOverlay {
  background-color: rgba(	38,	84,	124, .0);
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  color:transparent;
  z-index: 1;
}
.overlayInfo { 
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.ColumnHoverBubble {
  z-index: 1;
}

.HalfContainer {
  height: rem(300);
  overflow: hidden;
  color: white;
  border-radius: 25px;
}
