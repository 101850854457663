@import "src/Styles/variables";
@import "src/Styles/functions";

.ConnectContainer {
  height: auto;
  padding-bottom: rem(80);
}

.ConnectTitle {
  background-color: $Teal;
  padding: 10px 0px;
}
.aboutMe {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
}

.ConnectContents {
  margin: rem(60) rem(30);
  font-size: 1.2rem;
  line-height: 1.875rem;
}

.meContainer {
  height: rem(270);
  width: rem(270);
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid  rgba(45,117,112, .6);
  margin: rem(10);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

img {
  margin: -30px;
}

.infoBlock {
  width: 66%;
  margin: rem(10) auto rem(50);
  padding: rem(5);
  border-radius: 5px;
  color: #183e3b;
  text-align: left;
}

.socialLinks{ 
 // margin: rem(100);
  // display: flex;
  // flex-direction: row;
  // justify-content: space-evenly;
  // justify-content: flex-end;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  a {
    margin: 0 auto;
    font-size: rem(22);
    color: $Highlight;
    margin: rem(100);
    a:hover {
        color: $Highlight; 
    }
}
a:hover {
    cursor: pointer;
    font-weight: bold;
}
}
